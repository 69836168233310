const Post = Go.vue;

export default Post;

Post.methods.share = function(post) {
  this.view({
    post: post,
    title: `${this.$locale["share"]}`,
    component: () => import("../widgets/Share.vue"),
    outClose: true,
    class: "ShareView",
    creator: this.creator,
    animate: {
      duration: 250,
      from: {
        opacity: 0,
        transform: "scale(1.8)",
      },
      to: {
        opacity: 1,
        transform: "scale(1)",
      },
    },
  });
};
