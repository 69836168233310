var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"PostInfoMenu"},[_c('Spacer',{attrs:{"num":"1"}}),_c('div',{attrs:{"tcenter":""}},[_c('UserAvatar',{attrs:{"user":_vm.creator,"size":"120px","mauto":"","noprevent":"","active":"button"},on:{"click":_vm.openCreator}})],1),_c('Spacer',{attrs:{"num":"1"}}),_c('p',{attrs:{"strong":"","mauto":"","tcenter":"","noprevent":"","active":"button"},on:{"click":_vm.openCreator}},[_vm._v("@"+_vm._s(_vm.creator.user))]),_c('Spacer',{attrs:{"num":"1"}}),_c('LargeButton',{attrs:{"icon":("" + (!_vm.post.isSaved ? 'bookmark-o' : 'bookmark')),"label":("" + (!_vm.post.isSaved ? _vm.$locale['object']['post-context']['save-post'] : _vm.$locale['object']['post-context']['unsave-post']))},on:{"click":function($event){_vm.$user ? _vm.savePost() : _vm.modal.close(function () { return _vm.UserAdminMenu(); })}}}),_c('LargeButton',{attrs:{"icon":"eye","label":_vm.$locale['go_to_post']},on:{"click":function($event){_vm.modal.close(function () { return _vm.setQuery({ post_view: _vm.post._id }); })}}}),_c('LargeButton',{attrs:{"icon":"share_nodes","label":_vm.$locale['share_post']},on:{"click":function($event){_vm.modal.close(function () { return _vm.share(_vm.post); })}}}),(_vm.post.owner)?_c('div',{staticClass:"post-owner-options"},[_c('LargeButton',{attrs:{"icon":"pencil","label":_vm.$locale['object']['post-context']['edit-post']},on:{"click":function($event){return _vm.modalNav(("/post/edit/" + (_vm.post._id)))}}}),_c('LargeButton',{attrs:{"icon":"trash","label":_vm.$locale['delete_post']},on:{"click":function($event){_vm.modal.close(function () {
          _vm.view({
            post: _vm.post,
            class: 'menu bottom',
            animation: 'bottomIn',
            component: function () { return import('../creator/CreatorPostDeleteConfirm.vue'); },
            onDelete: function () {},
          });
        })}}})],1):_vm._e(),_c('Spacer',{attrs:{"num":"1"}}),_c('div',{attrs:{"prevent":""}},[_c('div',{attrs:{"dpadding":"","strong":""}},[_vm._v(_vm._s(_vm.$locale["post_info_title"]))]),(_vm.images)?_c('LargeButton',{attrs:{"icon":"image","label":_vm.$locale['images'],"rightDesc":("<small>" + _vm.images + "</small>")}}):_vm._e(),(_vm.videos)?_c('LargeButton',{attrs:{"icon":"ig_video","label":_vm.$locale['videos'],"rightDesc":("<small>" + _vm.videos + "</small>"),"align":"top"}},[_c('div',{directives:[{name:"gap",rawName:"v-gap",value:(['calc(var(--mpadding)/2)', '', '', '']),expression:"['calc(var(--mpadding)/2)', '', '', '']"}],staticClass:"infoVideos"},_vm._l((_vm.mediaVideos),function(video,index){return _c('LargeButton',{key:index,attrs:{"icon":"play","label":"Video","rightDesc":_vm.durationInfo(video),"line":"false"}})}),1)]):_vm._e(),_c('Spacer',{staticStyle:{"background-color":"var(--body-bg)"},attrs:{"num":"1"}}),_c('LargeButton',{attrs:{"icon":"calendars","label":_vm.$locale['date_post'],"rightDesc":("<small>" + (_vm.date_locale(_vm.post.date)) + "</small>")}})],1),_c('LargeButton',{attrs:{"icon":"message-exclamation","label":_vm.$locale['words']['report_post']},on:{"click":function($event){_vm.modal.close(function () {
        if (!_vm.$user) {
          return _vm.UserAdminMenu();
        }

        return _vm.view({
          post: _vm.post,
          class: 'menu bottom',
          animation: 'bottomIn',
          component: function () { return import('../creator/CreatorPostReport.vue'); },
          closeOutside: true,
        });
      })}}}),_c('Spacer',{attrs:{"num":"1"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }