<template>
  <div class="PostInfoMenu">
    <Spacer num="1" />
    <div tcenter>
      <UserAvatar :user="creator" size="120px" mauto @click="openCreator" noprevent active="button" />
    </div>
    <Spacer num="1" />
    <p strong mauto tcenter @click="openCreator" noprevent active="button">@{{ creator.user }}</p>
    <Spacer num="1" />

    <LargeButton
      :icon="`${!post.isSaved ? 'bookmark-o' : 'bookmark'}`"
      :label="`${!post.isSaved ? $locale['object']['post-context']['save-post'] : $locale['object']['post-context']['unsave-post']}`"
      @click="$user ? savePost() : modal.close(() => UserAdminMenu())"
    />

    <LargeButton :icon="`eye`" :label="$locale['go_to_post']" @click="modal.close(() => setQuery({ post_view: post._id }))" />

    <LargeButton :icon="`share_nodes`" :label="$locale['share_post']" @click="modal.close(() => share(post))" />

    <div class="post-owner-options" v-if="post.owner">
      <LargeButton icon="pencil" :label="$locale['object']['post-context']['edit-post']" @click="modalNav(`/post/edit/${post._id}`)" />
      <LargeButton
        icon="trash"
        :label="$locale['delete_post']"
        @click="
          modal.close(() => {
            view({
              post,
              class: 'menu bottom',
              animation: 'bottomIn',
              component: () => import('../creator/CreatorPostDeleteConfirm.vue'),
              onDelete: () => {},
            });
          })
        "
      />
    </div>

    <Spacer num="1" />

    <div prevent>
      <div dpadding strong>{{ $locale["post_info_title"] }}</div>
      <LargeButton v-if="images" icon="image" :label="$locale['images']" :rightDesc="`<small>${images}</small>`" />

      <LargeButton v-if="videos" icon="ig_video" :label="$locale['videos']" :rightDesc="`<small>${videos}</small>`" align="top">
        <div v-gap="['calc(var(--mpadding)/2)', '', '', '']" class="infoVideos">
          <LargeButton
            v-for="(video, index) in mediaVideos"
            :key="index"
            icon="play"
            :label="`Video`"
            :rightDesc="durationInfo(video)"
            line="false"
          />
        </div>
      </LargeButton>

      <Spacer num="1" style="background-color: var(--body-bg)" />

      <LargeButton icon="calendars" :label="$locale['date_post']" :rightDesc="`<small>${date_locale(post.date)}</small>`" />
    </div>

    <LargeButton
      icon="message-exclamation"
      :label="$locale['words']['report_post']"
      @click="
        modal.close(() => {
          if (!$user) {
            return UserAdminMenu();
          }

          return view({
            post,
            class: 'menu bottom',
            animation: 'bottomIn',
            component: () => import('../creator/CreatorPostReport.vue'),
            closeOutside: true,
          });
        })
      "
    />
    <Spacer num="1" />
  </div>
</template>

<script>
import PostInfo from "./PostInfo.js";
export default {
  mixins: [PostInfo],
  props: ["post", "modal"],
  methods: {
    savePost: function() {
      this.modal.close(async () => {
        this.$store.dispatch("savePost", { id: this.post._id, callback: this.saveResponse, creator: this.post.user._id });
      });
    },
    saveResponse: function(response) {
      this.post.isSaved = response.data.isSaved;
      this.showMessage(response.data);
    },
    openCreator: function() {
      this.modal.close(async () => {
        this.$router.push({ path: `/${this.post.user.user}`, query: this.$route.query }).catch((err) => {});
      });
    },
    durationInfo: function(media = {}, duration = null) {
      if (media.info && media.info.format) {
        duration = media.info.format.duration;
      }

      if (!duration) {
        return "";
      }

      let hours = Math.floor(duration / 3600);
      let minutes = Math.floor((duration - hours * 3600) / 60);
      let seconds = duration - hours * 3600 - minutes * 60;

      return `<span margin-left>
                <span>${hours ? Go.number_format(hours) + "h " : ""}</span>
                <span>${minutes ? Go.number_format(minutes) + "m " : ""}</span>
                <span>${Go.number_format(seconds)}s</span>
              </span>`;
    },
  },
  computed: {
    media: function() {
      return this.post.media || [];
    },
    creator: function() {
      return this.post.user;
    },
    images: function() {
      return this.post.info && this.post.info.countImages;
    },
    videos: function() {
      return this.post.info && this.post.info.countVideos;
    },
    mediaVideos: function() {
      return this.media.filter((item) => Go.isMedia(item, "video"));
    },
  },
};
</script>

<style lang="scss">
.PostInfoMenu {
  .infoVideos {
    font-size: 90%;
  }

  .userOnlineStatus {
    width: $mpadding * 2;
    height: $mpadding * 2;
    border: solid 3px #fff;
  }
}
</style>
